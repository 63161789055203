import { CTAButton, Heading1, Icon, P, TextLink, theme } from '@ovotech/element';
import styled from 'styled-components';
import { useState } from 'react';

import { EligibilityModal } from './EligibilityModal';

import { mqMediumUp } from '@utils/mediaQuery';
import EvChargerHeroLarge from '@resources/images/EvChargerHeroLarge.webp';
import EvChargerHeroSmall from '@resources/images/EvChargerHeroSmall.png';
import { DesktopOnly, MobileOnly } from '@components/layout/layout.styles';
import useEvCharger from '@hooks/useEvCharger';
import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';

const Container = styled.div`
  padding: 55px 16px 48px;
  ${mqMediumUp(`
    padding: 136px 16px 69px;
    text-align: center;
  `)};
`;

const Heading = styled(Heading1)`
  font-size: 64px;
  line-height: 64px;
  ${mqMediumUp(`
    font-size: 96px;
    line-height: 96px;
  `)};
`;

const Paragraph = styled(P)`
  ${({ theme }) => `
    font-size: 20px;
    line-height: 24px;
    padding-top: ${theme.core.space[4]};
    padding-bottom: ${theme.core.space[10]};
    ${mqMediumUp(`
      font-size: 24px;
      padding-top: ${theme.core.space[8]};
      padding-bottom: ${theme.core.space[14]};
    `)};
  `}
`;

const ModalTrigger = styled(TextLink)`
  cursor: pointer;
`;

const InfoIcon = styled(Icon)`
  cursor: pointer;
`;

const EligibilityRow = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.core.space[10]};
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const StyledCTAButton = styled(CTAButton)`
  ${mqMediumUp(`
    width: 336px;  
  `)}
`;

export const HeroSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { quotes } = useEvCharger();
  const quote = quotes.find((quote) => quote.excess);
  const { track } = useCustomMixpanel();
  const formatNumber = (num: number) => {
    return num % 1 === 0 ? `${num}` : num.toFixed(2);
  };

  const handleModalOpen = () => {
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: 'eligibilityModal',
    });
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCTAButtonClick = () => {
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: 'heroAnchorCTA',
    });
  };

  return (
    <div>
      <Container>
        <Heading>EV Charger Cover.</Heading>

        <Paragraph>Insurance to help keep your EV on the road.</Paragraph>

        <a href="#choose-your-cover">
          <StyledCTAButton onClick={handleCTAButtonClick}>
            {quote
              ? `Choose cover from £${formatNumber(quote.originalPrice || quote.price)}`
              : 'Choose cover'}
          </StyledCTAButton>
        </a>

        <EligibilityRow>
          <InfoIcon
            name="infoBold"
            color={theme.semantic.message.link}
            size={18}
            onClick={handleModalOpen}
          />
          <ModalTrigger onClick={handleModalOpen}>Check you’re eligible</ModalTrigger>
          <EligibilityModal isOpen={isModalOpen} onClose={handleModalClose} />
        </EligibilityRow>
      </Container>

      <MobileOnly>
        <img src={EvChargerHeroSmall} width="100%" />
      </MobileOnly>
      <DesktopOnly>
        <img src={EvChargerHeroLarge} width="100%" />
      </DesktopOnly>
    </div>
  );
};
