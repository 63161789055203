import { Heading3, P } from '@ovotech/element';
import { ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { mqLargeUp } from '@utils/mediaQuery';
import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';

type CardProps = {
  withBoxShadow?: boolean;
};

const Card = styled.div<CardProps>(
  ({ withBoxShadow, theme }) => css`
    width: 270px;
    height: 100%;
    background: ${theme.semantic.surface.elevated};
    padding: 42px 24px 42px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 21px;
    box-shadow: ${withBoxShadow && '0px -20px 60px -20px #0B251D80;'};
    ${mqLargeUp(`
      width: 100%;
      height: auto;
      gap: 0;
      display: grid;
      grid-template-columns: 160px minmax(0, 1fr);
    `)};
  `
);

const LeftCol = styled.div`
  display: flex;
  ${mqLargeUp(`
    justify-content: center;
    align-items: center;
  `)};
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled(Heading3)`
  font-size: 28px;
  line-height: 28px;
  white-space: pre-wrap;
  color: ${({ theme }) => theme.semantic.message.base};
  ${mqLargeUp(`
    font-size: 48px;
    line-height: 48px;
  `)};
`;

const Image = styled.img`
  height: 120px;
  width: auto;
  ${mqLargeUp(`
    height: auto;
    width: 120px;
  `)};
`;

const Paragraph = styled(P)`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.semantic.message.secondary};
  ${mqLargeUp(`
    font-size: 24px;
    line-height: 28px;
  `)};
`;

type BenefitCardProps = {
  title: string;
  description: ReactNode;
  imageSrc: string;
  withBoxShadow?: boolean;
  isActive?: boolean;
};

export const BenefitCard = ({
  title,
  description,
  imageSrc,
  withBoxShadow,
  isActive,
}: BenefitCardProps) => {
  const { track } = useCustomMixpanel();

  useEffect(() => {
    if (isActive) {
      track(MixpanelEvents.CAROUSEL_SLIDE_ACTIVE, {
        carouselId: 'benefitsUSP',
        slideId: title,
      });
    }
  }, [isActive]);

  return (
    <Card withBoxShadow={withBoxShadow}>
      <LeftCol>
        <Image src={imageSrc} />
      </LeftCol>

      <RightCol>
        <Heading>{title}</Heading>
        <Paragraph>{description}</Paragraph>
      </RightCol>
    </Card>
  );
};
