import styled from 'styled-components';
import { Heading3, P } from '@ovotech/element';

import LargeImage from '@resources/images/EvChargerTrustPilotLarge.png';
import SmallImage from '@resources/images/EvChargerTrustPilotSmall.png';
import { TrustpilotWidget } from '@components/TrustpilotWidget/TrustpilotWidget';
import { useMediaIs } from '@hooks/useMediaIs';
import { mqMediumUp } from '@utils/mediaQuery';

const Section = styled.div`
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 60%;
    left: 0%;
    bottom: 3%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    filter: blur(26px);
    ${mqMediumUp(`
      width: 55%;
      height: 35%;
    `)}
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 5%;
  max-width: ${({ theme }) => theme.core.breakpoint.large};
  left: 0;
  right: 0;
  padding: 0 16px;
  margin: auto;
  ${mqMediumUp(`
    padding: 0 32px;
    bottom: 10%;
  `)};
`;

const Content = styled.div`
  position: relative;
  max-width: 500px;
  color: white;
  text-shadow: rgba(0, 0, 0, 1) 5px 0 15px;
  ${mqMediumUp(`
    max-width: 660px;
  `)};
`;

const Heading = styled(Heading3)`
  font-weight: 900;
  font-size: 48px;
  line-height: 48px;
  color: white;
  margin-top: 16px;
  ${mqMediumUp(`
    font-size: 64px;
    line-height: 60px;
    margin-top: 12px;
  `)};
`;

const Paragraph = styled(P)`
  color: white;
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;

  ${mqMediumUp(`
    margin-top: 24px;
  `)};
`;

const StyledTrustpilotWidget = styled.div`
  position: absolute;
  top: -30px;
  left: -31px;
`;

export const TrustSection = () => {
  const isMobile = useMediaIs('mobile', 'max');
  const imageSrc = isMobile ? SmallImage : LargeImage;

  return (
    <Section>
      <img src={imageSrc} width="100%" />

      <Container>
        <Content>
          <StyledTrustpilotWidget>
            <TrustpilotWidget
              templateId="5419b637fa0340045cd0c936"
              theme="dark"
              height="30px"
              width="100%"
            />
          </StyledTrustpilotWidget>
          <Heading>Trusted by thousands of UK homes</Heading>

          <Paragraph>
            We insure boilers, electrics and more for thousands of customers. So when it comes to
            protecting what matters most, you’re in safe hands.
          </Paragraph>
        </Content>
      </Container>
    </Section>
  );
};
