import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { EffectCreative } from 'swiper/modules';
import styled from 'styled-components';
import { ReactNode, useRef, useState } from 'react';

import { CarouselNavigation } from './CarouselNavigation';
import { BenefitCard } from './BenefitCard';

import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';
import { mqMediumUp } from '@utils/mediaQuery';

const Root = styled.div`
  display: flex;
  flex-direction: column;

  ${mqMediumUp(`
    justify-self: center;
    max-width: 580px;
  `)}
`;

const Container = styled(SwiperSlide)`
  .swiper-slide {
    width: fit-content;
    height: auto;
  }
`;

const Wrapper = styled.div`
  margin-top: 24px;
  padding-left: 16px;
`;

type Card = {
  imageSrc: string;
  title: string;
  description: ReactNode;
};

type SwiperState = {
  isBeginning: boolean;
  isEnd: boolean;
  allowSlidePrev: boolean;
  allowSlideNext: boolean;
};

type BenefitsCarouselProps = {
  cards: Card[];
};

export const BenefitsCarousel = ({ cards }: BenefitsCarouselProps) => {
  SwiperCore.use([EffectCreative]);

  const { track } = useCustomMixpanel();
  const swiperRef = useRef<SwiperClass | undefined>();
  const [swiperState, setSwiperState] = useState<SwiperState>({
    isBeginning: false,
    isEnd: false,
    allowSlidePrev: false,
    allowSlideNext: false,
  });

  const handleStateChange = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
    const { isBeginning, isEnd, allowSlidePrev, allowSlideNext, activeIndex } = swiper;

    if (activeIndex == 3) {
      swiper.allowTouchMove = false;
    }

    setSwiperState({
      isBeginning,
      isEnd,
      allowSlidePrev,
      allowSlideNext: activeIndex == 3 ? false : allowSlideNext,
    });
  };

  const handlePrev = () => {
    track(MixpanelEvents.CAROUSEL_NAVIGATION_BUTTON_CLICK, {
      carouselId: 'benefitsUSP',
      buttonId: 'previous',
    });
    swiperRef.current?.slidePrev();
  };

  const handleNext = () => {
    track(MixpanelEvents.CAROUSEL_NAVIGATION_BUTTON_CLICK, {
      carouselId: 'benefitsUSP',
      buttonId: 'next',
    });
    swiperRef.current?.slideNext();
  };

  const handleTouchMove = () => {
    if (swiperRef.current?.touches.diff) {
      track(MixpanelEvents.CAROUSEL_TOUCH_SWIPE, {
        carouselId: 'benefitsUSP',
        touchDirection: swiperRef.current?.touches.diff < 0 ? 'forward' : 'backward',
      });
    }
  };

  const creativeEffect = {
    prev: {
      translate: [0, -150, -1000],
      opacity: 0,
    },
    next: {
      translate: [286, 0, 0],
    },
    limitProgress: 4,
  };

  return (
    <Root>
      <Container id="carousel-container">
        <Swiper
          slidesPerView="auto"
          spaceBetween={16}
          slidesOffsetAfter={500}
          slidesOffsetBefore={16}
          onSwiper={handleStateChange}
          onSlideChange={handleStateChange}
          onReachEnd={handleStateChange}
          onReachBeginning={handleStateChange}
          onUpdate={handleStateChange}
          onResize={handleStateChange}
          onTouchEnd={handleTouchMove}
          speed={1000}
          effect={'creative'}
          creativeEffect={creativeEffect}
        >
          {cards.map(({ imageSrc, title, description }) => (
            <SwiperSlide key={title}>
              {({ isActive }) => (
                <BenefitCard
                  imageSrc={imageSrc}
                  title={title}
                  description={description}
                  isActive={isActive}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      <Wrapper>
        <CarouselNavigation
          allowSlideNext={swiperState.allowSlideNext}
          allowSlidePrev={swiperState.allowSlidePrev}
          isBeginning={swiperState.isBeginning}
          isEnd={swiperState.isEnd}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      </Wrapper>
    </Root>
  );
};
