import styled from 'styled-components';
import { Heading1, Heading4 } from '@ovotech/element';
import React from 'react';

import HowItWorksBackground from '@resources/images/electric-vehicle-mountain-road.png';
import { mqLargeUp, mqMediumUp } from '@utils/mediaQuery';
import { evChargerCoverWarrantyFootnote } from '@constants/footnotes';
import { SupRef } from '@components/Footer';

const ContainerWrapper = styled.div`
  ${({ theme }) => `
    padding: 88px 16px 233px;
    background-color: ${theme.core.color.brand.offwhite};
    position: relative;
    
    ${mqMediumUp(`
      padding: 148px 16px 290px;
    `)};
    
    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -20px;
      left: 0;
      right: 0;
      background-size: contain;
      background-image: url(${HowItWorksBackground});
      background-repeat: no-repeat;
      background-position: bottom right;
      min-height: 300px;

      ${mqMediumUp(`
        height: 910px;
        bottom: -60px;
        z-index: auto;
      `)};
    }
  `}
`;

const Container = styled.div`
  ${mqLargeUp(`
    width: 1440px;
    margin-left: auto;
    margin-right: auto;
  `)};
`;

const Content = styled.div`
  ${mqLargeUp(`
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  `)};
`;

const StyledHeading1 = styled(Heading1)`
  ${({ theme }) => `
    font-size: 48px;
    line-height: 1;
    letter-spacing: -3px;
    max-width: 100%;
    color: ${theme.core.color.brand.ovo};
    margin-bottom: ${theme.core.space[8]};

    ${mqMediumUp(`
      font-size: 64px;
      margin-bottom: 56px;
    `)};
  `}
`;

const HowClaimsWorkItems = styled.div`
  ${({ theme }) => `
    padding-left: ${theme.core.space[8]};
  `}
`;

const HowClaimsWorkItem = styled.div`
  ${({ theme }) => `
    background-color: ${theme.core.color.brand.white};
    padding: ${theme.core.space[6]};
    margin-bottom: ${theme.core.space[3]};
    max-width: 650px;
    border-radius: 16px;
    position: relative;

    ${mqMediumUp(`
      max-width: 437px;
      margin-bottom: ${theme.core.space[4]};
    `)};
    
    &::before {
      content: '';
      position: absolute;
      left: -32px;
      height: 20px;
      width: 20px;
      top: 15%;
      border-radius: 50%;
      border: 4px solid ${theme.core.color.brand.ovoTint};
      background-color: ${theme.core.color.brand.offwhite};
      z-index: 2;

      ${mqMediumUp(`
        top: 45%;
      `)};
    }
    
    &:not(:first-child){
      &::after {
        content: '';
        left: -23px;
        top: -140px;
        bottom: 80px;
        width: 2px;
        position: absolute;
        background: repeating-linear-gradient(
          to bottom,
          ${theme.core.color.brand.ovoTint},
          ${theme.core.color.brand.ovoTint} 6px,
          transparent 6px,
          transparent 10px
        );
        clip-path: inset(0 round 2px);

        ${mqMediumUp(`
          top: -80px;
          bottom: 40px;
        `)};
      }
    }
    
    &:last-child {
      &::after {
        bottom: 75%;

        ${mqMediumUp(`
          bottom: 45%;
        `)};
      }
    }
  `}
`;

const HowClaimsWorkItemTitle = styled(Heading4)`
  ${({ theme }) => `
    font-size: 20px;
    line-height: 1.2;
    color: #00261C;
    margin-bottom: ${theme.core.space[2]};
  `}
`;

const HowItWorksItemDescription = styled.div`
  ${({ theme }) => `
    color: ${theme.semantic.message.secondary}
  `}
`;

type HowItWorksStep = {
  title: React.ReactNode;
  description: string;
};

const howClaimsWorkSteps: HowItWorksStep[] = [
  {
    title: 'Support 24/7',
    description: `Give us a call, tell us what's happened, and we'll guide you through quick fixes you can try at home. Or we'll book in a home visit from an engineer.`,
  },
  {
    title: 'An engineer checks the problem ',
    description:
      'We aim to have an engineer out to take a look at your charger as quick as we can.',
  },
  {
    title: 'We repair or replace your EV charger',
    description:
      'If the engineer can’t repair your charger, we’ll fit your replacement as soon as possible.',
  },
];

export const HowClaimsWorkSection = () => {
  const repairStep = (title: React.ReactNode) => title === 'We repair or replace your EV charger';

  return (
    <ContainerWrapper>
      <Container>
        <Content>
          <StyledHeading1>How claims work</StyledHeading1>
          <HowClaimsWorkItems>
            {howClaimsWorkSteps.map((step, index) => (
              <HowClaimsWorkItem key={index}>
                <HowClaimsWorkItemTitle>{step.title}</HowClaimsWorkItemTitle>
                <HowItWorksItemDescription>
                  {step.description}
                  {repairStep(step.title) && <SupRef footnote={evChargerCoverWarrantyFootnote} />}
                </HowItWorksItemDescription>
              </HowClaimsWorkItem>
            ))}
          </HowClaimsWorkItems>
        </Content>
      </Container>
    </ContainerWrapper>
  );
};
